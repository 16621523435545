<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pamatdati
            </h3>
        </template>
        <template v-slot:buttons>

            <!-- Add subcategory button -->
            <Button icon="plus" @click="showForm('AddSubcategory')" tooltip="Pievienot apakškategoriju"/>

            <template v-if="category.products_count">
                <Button icon="sitemap" @click="openProducts" tooltip="Skatīt produktus"/>
            </template>

            <!-- Edit button -->
            <Button icon="pencil" @click="showForm('EditCategoryDetails')"/>

            <!-- Delete button -->
            <Button v-if="category.allow_delete" icon="delete" @click="deleteCategory"/>

        </template>

        <template v-slot:content>

            <template v-if="!displayForm('EditCategoryDetails') && !displayForm('AddSubcategory')">
                <div class="w-full grid grid-cols-12">

                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="#" :text="category.id"/>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Apakškategorijas" :text="category.subcategories_count"/>
                    </div>

                    <template v-if="category.products_count">
                        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                            <ItemText title="Produktu skaits" :text="category.products_count"/>
                        </div>
                    </template>

                    <template v-if="category.parent">
                        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                            <ItemText title="Virskategorija" :text="category.parent.name"/>
                        </div>
                    </template>

                    <div class="flex-1 col-span-12 pt-4">
                        <template v-if="!displayForm('EditCategoryOptions')">

                            <div class="flex items-center ">
                                <div class="sm:flex sm:space-x-10">
                                    <ItemText title="Uzkaite ar unikāliem kodiem" :text="category.use_unique_codes ? 'Jā' : 'Nē'"/>
                                    <ItemText title="Reversais PVN" :text="category.reverse_vat ? 'Jā' : 'Nē'"/>
                                </div>

                                <div class="ml-auto">
                                    <Button icon="pencil" @click="showForm('EditCategoryOptions')"/>
                                </div>
                            </div>

                        </template>

                        <template v-if="displayForm('EditCategoryOptions')">
                            <div class="flex h-full items-emd">
                                <EditCategoryOptions :category="category"/>
                            </div>
                        </template>
                    </div>

                    <div class="flex-1 col-span-12 pt-4">
                        <template v-if="!displayForm('EditCategoryKnCodeWeightCountry')">

                            <div class="flex items-center ">
                                <div class="sm:flex sm:space-x-10">
                                    <ItemText title="KN kods" :text="category.kn_code ?? 'Nav norādīts'"/>
                                    <ItemText title="Vidējais svars / kg" :text="category.weight ?? 'Nav norādīts'"/>
                                    <ItemText title="Valsts" :text="category.country ? category.country.name : 'Nav norādīta'"/>
                                </div>

                                <div class="ml-auto">
                                    <Button icon="pencil" @click="showForm('EditCategoryKnCodeWeightCountry')"/>
                                </div>
                            </div>

                        </template>

                        <template v-if="displayForm('EditCategoryKnCodeWeightCountry')">
                            <div class="flex h-full items-emd">
                                <EditCategoryKnCodeWeightCountry :category="category"/>
                            </div>
                        </template>
                    </div>


                </div>
            </template>

            <template v-if="displayForm('EditCategoryDetails')">
                <EditCategory :category="category"/>
            </template>

            <template v-if="displayForm('AddSubcategory')">
                <AddSubcategory :category="category"/>
            </template>

        </template>
    </item-card>
</template>

<script>
import {mapGetters} from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

import {defineAsyncComponent} from 'vue'

const EditCategory = defineAsyncComponent(() =>
  import('@/components/Settings/Category/EditCategory'))
const AddSubcategory = defineAsyncComponent(() =>
  import('@/components/Settings/Category/AddSubcategory'))
const EditCategoryOptions = defineAsyncComponent(() =>
  import('./EditCategoryOptions'))
const EditCategoryKnCodeWeightCountry = defineAsyncComponent(() =>
    import('./EditCategoryKnCodeWeightCountry'))

export default {
    name: "CategoryDetails",
    components: {
        ItemCard,
        ItemText,
        EditCategory,
        AddSubcategory,
        EditCategoryOptions,
        EditCategoryKnCodeWeightCountry
    },
    props: ['category'],
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        openProducts() {
            this.$store.dispatch('changeSearch', '')
            this.$router.push(`/categories/${this.category.id}/products`)
        },
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
        deleteCategory() {
            if (confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('deleteCategory', this.category.id)
                    .then(response => {
                        this.$Progress.finish()
                    }).catch(error => {
                    this.$Progress.fail()
                })
            }
        },
    }
}
</script>
